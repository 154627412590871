import React from 'react';
import './Footer.css';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={`${process.env.PUBLIC_URL}/sertiva_logo.png`} alt="SERTIVA Logo" className="sertiva-logo" />
        </div>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/leo-serton-a78b901b4/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="social-icon" />
          </a>
          <a href="https://github.com/LeoSerton" target="_blank" rel="noopener noreferrer">
            <FaGithub className="social-icon" />
          </a>
        </div>
      </div>
      <div className="footer-rights">
        &copy; 2024 All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
