import React from 'react';
import ProjectCard from './ProjectCard';
import './Projects.css';

const projects = [
  {
    title: 'C-PRO',
    description: 'A professional website developed for a corporate client in the construction & project management industry.',
    image: '/cpro_logo.png',
    link: 'https://cpropm.co.za'
  },
  {
    title: 'AlphaFunder',
    description: 'A website built for a new prop firm in South Africa, featuring robust functionality and sleek design.',
    image: '/AF_newLogo.png',
    link: 'https://alphafunder.io'
  },
  {
    title: 'AGRITA',
    description: 'A data management web-app for livestock breeders.',
    image: '/Agrita_logo.png',
    link: 'https://link-to-agrita.com'
  },
  {
    title: 'Calculator App',
    description: 'A simple calculator app with basic arithmetic functions.',
    image: '/calculator1.png',
    link: 'https://github.com/LeoSerton/calculatorApp/tree/main'
  },
  {
    title: 'X\'s & O\'s',
    description: 'A fun tic-tac-toe game to play against a computer or a friend.',
    image: '/x-o.png',
    link: 'https://github.com/LeoSerton/ResponsiveX/tree/main'
  },
  {
    title: 'AGRITA App',
    description: 'A mobile app for data management tailored for livestock breeders.',
    image: '/Agrita_logo.png',
    link: 'https://link-to-agritaapp.com'
  }
];

const Projects = () => {
  return (
    <div className="projects">
      <h1>Projects</h1>
      <div className="project-grid">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
