import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="about">
      <div className="content">
        <h1>About Me</h1>
        <div className="container">
          <p>
            Hi! My name is Leo Serton and I am currently studying a degree in Computer Science from the University of London. My passion lies in developing innovative applications and websites. I thrive in collaborative environments, contributing to both the design and development processes.
          </p>
        </div>
        <div className="container">
          <h2>Current Projects</h2>
          <p>
            Currently, I am engaged in several exciting projects. I am building a website for AlphaFunder, a new prop firm, and developing AGRITA, my own startup focused on creating a data management app for livestock breeders.
          </p>
        </div>
        <div className="container">
          <h2>Skills and Interests</h2>
          <p>
            My diverse skill set includes excellent communication and interpersonal abilities, which enable me to work effectively with team members and clients. I am always eager to learn new technologies and methodologies to improve my craft and deliver high-quality solutions.
          </p>
        </div>
        <div className="container">
          <h2>Additional Interests</h2>
          <p>
            In my free time, I am a licensed drone pilot. I perform site inspections, create progress reports, develop 3D site models, and assist with project management. This hobby complements my technical skills and broadens my expertise in various fields.
          </p>
        </div>
        {/* <button to="/contact" className="cta-button">Contact Me</button> */}
        <Link to="/contact" className="cta-button">Contact Me</Link>
      </div>
    </div>
  );
};

export default About;
