import React, { useState } from 'react';
import './ProjectCard.css';

const ProjectCard = ({ project }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isSpecialProject = project.title === 'Calculator App' || project.title === 'X\'s & O\'s';
  const isAlphaFunder = project.title === 'AlphaFunder';

  return (
    <div className={`project-card ${isExpanded ? 'expanded' : ''}`} onClick={handleToggle}>
      <img
        src={project.image}
        alt={project.title}
        className={`project-image ${isSpecialProject ? 'special' : ''} ${isAlphaFunder ? 'alpha-funder' : ''}`}
      />
      <h3>{project.title}</h3>
      {isExpanded && (
        <div className="project-details">
          <p>{project.description}</p>
          {project.title.includes('AGRITA') ? (
            <button className="coming-soon-button">Coming Soon</button>
          ) : (
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              View Project
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
