import React from 'react';
import { Link } from 'react-router-dom';
import { FaHtml5, FaCss3Alt, FaJsSquare, FaNodeJs } from 'react-icons/fa';
import { SiReact, SiPython, SiCplusplus } from 'react-icons/si';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <div className="content">
        <h1>Welcome to SERTIVA</h1>
        <p>Your one-stop portfolio for showcasing my development projects and skills.</p>
        <Link to="/projects" className="cta-button">Explore My Work</Link><br></br>
        <Link to="/about" className="cta-button">Find out more about me</Link>
      </div>
      <div className="skills">
        <h2>Technologies I've Worked With</h2>
        <div className="skills-grid">
          <div className="skill-item">
            <FaHtml5 />
            <p>HTML</p>
          </div>
          <div className="skill-item">
            <FaCss3Alt />
            <p>CSS</p>
          </div>
          <div className="skill-item">
            <FaJsSquare />
            <p>JavaScript</p>
          </div>
          <div className="skill-item">
            <FaNodeJs />
            <p>Node.js</p>
          </div>
          <div className="skill-item">
            <SiReact />
            <p>React</p>
          </div>
          <div className="skill-item">
            <SiReact />
            <p>React Native</p>
          </div>
          <div className="skill-item">
            <SiPython />
            <p>Python</p>
          </div>
          <div className="skill-item">
            <SiCplusplus />
            <p>C++</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
