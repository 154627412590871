import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5rnu1rq', 'template_ds4kosk', form.current, '6yGynKxMtxxVhwlAh')
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send message. Please try again later.');
      });

    e.target.reset();
  };

  return (
    <div className="contact">
      <h1>Contact Me</h1>
      <div className="container">
        <div className="card">
          <div className="contact-details">
            <h2>Contact Details</h2>
            <p><strong>Name:</strong> Leo Serton</p>
            <p><strong>Phone:</strong> +27 76 318 4184</p>
            <p><strong>Email:</strong> l.serton@gmail.com</p>
          </div>
          <form ref={form} onSubmit={sendEmail} className="contact-form">
            <label>Name</label>
            <input type="text" name="from_name" required />
            <label>Email</label>
            <input type="email" name="user_email" required />
            <label>Message</label>
            <textarea name="message" required />
            <button type="submit" className="submit-button">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
